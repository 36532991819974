define("ln-liga-os/initializers/version-info", ["exports", "jquery", "ln-liga-os/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var BUILD_INFO = _environment.default.BUILD_INFO;
  function log() {
    var _console;
    for (var _len = arguments.length, parts = new Array(_len), _key = 0; _key < _len; _key++) {
      parts[_key] = arguments[_key];
    }
    var colored = /(firefox|chrome)/i.test(navigator.userAgent);
    parts = parts.reduce(function (parts, part) {
      if (Array.isArray(part)) {
        if (colored && part.length >= 2) {
          parts.push("%c".concat(part[0]));
          parts.push(part[1]);
        } else {
          parts.push(part[0]);
        }
      } else {
        parts.push(part);
      }
      return parts;
    }, []);

    // eslint-disable-next-line no-console
    (_console = console).log.apply(_console, _toConsumableArray(parts));
  }
  function logInfos(infos) {
    var length = Object.keys(infos).reduce(function (length, key) {
      return Math.max(length, key.length);
    }, 0);
    Object.keys(infos).sort().forEach(function (key) {
      var pad = ' '.repeat(length - key.length);
      log(["".concat(key).concat(pad, " :"), 'color: gray;'], infos[key]);
    });
  }
  function add(target, key, source) {
    target[key] = source[key];
    return target;
  }
  function logSepertor() {
    log('----------------------------------------------------------');
  }
  function logBuildInfo() {
    logSepertor();
    log(['[ LIGA OS ]', 'font-weight: bold;']);
    logInfos(Object.keys(BUILD_INFO).filter(function (key) {
      return /^BUILD_.*/.test(key);
    }).filter(function (key) {
      return !Ember.isNone(BUILD_INFO[key]);
    }).reduce(function (infos, key) {
      return add(infos, key, BUILD_INFO);
    }, {}));
  }
  function logResponseInfo(responses) {
    logSepertor();
    log(['[ API versions ]', 'font-weight: bold;']);
    logInfos(Object.keys(responses).reduce(function (versions, key) {
      if (_typeof(responses[key]) === 'object') {
        return Object.keys(responses[key]).reduce(function (versions, type) {
          return add(versions, type, responses[key]);
        }, versions);
      }
      return versions;
    }, {}));
  }
  function initialize(application) {
    var promises = {};
    var apiVdc = application.get('config.api-vdc.url');
    if (apiVdc) {
      promises['api-vdc'] = _jquery.default.getJSON("".concat(apiVdc, "/v1/version_infos"));
    }
    var apiMyliga = application.get('config.api-myliga.url');
    if (apiMyliga) {
      promises['api-vdc'] = _jquery.default.getJSON("".concat(apiMyliga, "/v1/version_infos"));
    }
    Ember.RSVP.hash(promises).then(function (responses) {
      logBuildInfo();
      logResponseInfo(responses);
      logSepertor();
    }).catch(function () {
      logBuildInfo();
      logSepertor();
    });
  }
  var _default = _exports.default = {
    name: 'version-info',
    initialize: initialize
  };
});
