define("ln-liga-os/models/vdc/sales-figure", ["exports", "ln-ember-data-models/models/vdc/sales-figure"], function (_exports, _salesFigure) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _salesFigure.default;
    }
  });
});
