define("ln-liga-os/adapters/vdc/contextual-role-assignment", ["exports", "ln-ember-data-models/adapters/vdc/contextual-role-assignment"], function (_exports, _contextualRoleAssignment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _contextualRoleAssignment.default;
    }
  });
});
