define("ln-liga-os/models/vdc/goods-movement", ["exports", "ln-ember-data-models/models/vdc/goods-movement"], function (_exports, _goodsMovement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _goodsMovement.default;
    }
  });
});
