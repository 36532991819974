define("ln-liga-os/helpers/inline-css-props", ["exports", "ln-ember-toolkit/helpers/inline-css-props"], function (_exports, _inlineCssProps) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _inlineCssProps.default;
    }
  });
  Object.defineProperty(_exports, "inlineCssProps", {
    enumerable: true,
    get: function get() {
      return _inlineCssProps.inlineCssProps;
    }
  });
});
