define("ln-liga-os/transforms/enum-string", ["exports", "ln-ember-data-models/transforms/enum-string"], function (_exports, _enumString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _enumString.default;
    }
  });
});
