define("ln-liga-os/components/contact-list/item-subhead", ["exports", "ln-liga-os/components/contact-list/item-base"], function (_exports, _itemBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _itemBase.default.extend({
    tagName: 'span',
    classNames: ['contact-list-item-subhead-component', 'subhead'],
    subhead: Ember.computed.alias('item.text')
  });
});
