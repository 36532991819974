define("ln-liga-os/components/app-content", ["exports", "ln-liga-os/utils/inject-config", "ln-ember-toolkit/utils/liga-os"], function (_exports, _injectConfig, _ligaOs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    navigation: Ember.inject.service(),
    state: Ember.inject.service(),
    classNames: ['app-content-component'],
    classNameBindings: ['loaded', 'state.communicatorIsOpen', 'showBackground'],
    attributeBindings: ['style'],
    url: null,
    config: (0, _injectConfig.default)(),
    style: Ember.computed('showBackground', 'navigation.currentApp.background', function () {
      var bg = 'transparent';
      if (this.showBackground) {
        bg = this.get('navigation.currentApp.background');
      }
      return "background-color: ".concat(bg).htmlSafe();
    }),
    showBackground: Ember.computed('state.{appLoaded,appError}', 'navigation.currentApp.background', function () {
      var _this$navigation$curr;
      return !!(this.state.appLoaded && !this.state.appError && (_this$navigation$curr = this.navigation.currentApp) !== null && _this$navigation$curr !== void 0 && _this$navigation$curr.background);
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.navigation.on('open-url', this, this.updateFrameUrl);
      this.updateFrameUrl();
    },
    willRemoveElement: function willRemoveElement() {
      this._super.apply(this, arguments);
      this.navigation.off('open-url', this, this.updateFrameUrl);
    },
    updateFrameUrl: function updateFrameUrl() {
      var _this = this;
      var currentUrl = this.navigation.currentUrl;
      if (!currentUrl) {
        return;
      }
      Ember.run.scheduleOnce('afterRender', function () {
        if (!_this.element || _this.isDestroyed) {
          return;
        }
        var _location = location,
          origin = _location.origin;
        var fullUrl = currentUrl.match(/^\/[^/]/) ? "".concat(origin).concat(currentUrl) : currentUrl;
        var iframeUrl = new URL(fullUrl);
        var app = _this.navigation.getAppFor(currentUrl);
        if (app) {
          var ligaOsPrefix = "".concat(origin, "/#/!/").concat(app.slug);
          iframeUrl.searchParams.append(_ligaOs.PREFIX_QUERY_PARAM_NAME, ligaOsPrefix);
        }
        var appFrame = _this.element.querySelector('.app-frame');
        appFrame && appFrame.setAttribute('src', iframeUrl.toString());
      });
    }
  });
});
