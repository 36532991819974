define("ln-liga-os/services/liga-os-api", ["exports", "jquery", "ln-liga-os/utils/browser", "ln-liga-os/utils/inject-config"], function (_exports, _jquery, _browser, _injectConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var LOG_COLOR = 'green';
  var LOG_GROUP_FUNC = 'group';

  /**
   * ## Events
   *
   * Apps can listen to events triggered by LIGA OS.
   *
   * **Example**
   *
   * ```JavaScript
   * window.addEventListener('message', (evt) => {
   *   if (evt.data.event === 'my-event-identifier') {
   *     // handle it
   *   }
   * }, false);
   * ```
   *
   * ### Pub Sub Events
   *
   * All Events coming in through the PubSubService are prefixed with `event:`. So if
   * you want to get notified, when the `my-event-identifier` occurs, you need to
   * listen to `event:my-event-identifier`.
   *
   *
   * ## Integration
   *
   * These methods can be called on LigaOsApi Service via `postMessage()` on the
   * parent window.
   *
   * For example:
   *
   * ```JavaScript
   * parent.window.postMessage({idx:'foo-1', method:'logout', args:[] }, '*');
   * ```
   *
   * ```JavaScript
   * window.addEventListener('message', function(evt) {
   *   if (evt.data.idx) {
   *     // data.idx will be the same as provided above
   *     // data.response contains the response.
   *   }
   * }, false);
   * ```
   *
   * See: [ln-subsystem-helper liga-os.js ](https://bitbucket.org/ligadigital/ln-subsystem-helper/src/master/js/liga-os.js)
   *
   * This can be used like this:
   *
   * ```JavaScript
   * ligaOS.call('getLanguage', [], function(token) {
   *   console.log('language:', token);
   * })
   *
   * ligaOS.call('getLanguage', ['en']);
   * ```
   *
   * @class LigaOsApi
   */
  var _default = _exports.default = Ember.Service.extend({
    state: Ember.inject.service(),
    session: Ember.inject.service(),
    pubSub: Ember.inject.service(),
    navigation: Ember.inject.service(),
    apiEvents: Ember.inject.service(),
    config: (0, _injectConfig.default)(),
    communicator: Ember.inject.service(),
    clientTheme: Ember.inject.service(),
    settings: Ember.inject.service(),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.pubSub.on('event', function (_ref) {
        var name = _ref.name;
        _this.triggerEvent("event:".concat(name));
      });
    },
    // TODO: implement `path` and `queryParams` arguments
    buildUrl: function buildUrl(slug) {
      var apps = this.navigation.getApps();
      var app = apps.find(function (app) {
        return app.get('slug') === slug;
      });
      if (!app) {
        return;
      }
      // TODO: Deal with "//" vs "/" somehow
      return "".concat(location.origin, "/#/!//").concat(slug, "/");
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @deprecated
     * @method setUrlPath
     * @param {string} url
     */
    setUrlPath: function setUrlPath(url) {
      this._logDeprecatedCall.apply(this, [['setUrlPath', 'setUrl() or openUrl'], '2.3.0'].concat(Array.prototype.slice.call(arguments)));
      this.navigation.setUrl(url);
    },
    // Navigation
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method openUrl
     * @param  {String} url
     */
    openUrl: function openUrl(url) {
      this._logCall.apply(this, ['openUrl'].concat(Array.prototype.slice.call(arguments)));
      this.navigation.openUrl(url);
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method openResource
     * @param  {String} resource
     * @param  {Number} resourceId
     */
    openResource: function openResource(resource, resourceId) {
      this._logCall.apply(this, ['openResource'].concat(Array.prototype.slice.call(arguments)));
      this.navigation.openResource(resource, resourceId);
    },
    /**
     * This should be called by the App whenever the App's URL changes. It makes
     * sure the URL hash in the browser is up-to-date.
     *
     * @memberof LigaOsApi
     * @instance
     *
     * @method setUrl
     * @param  {String} url
     */
    setUrl: function setUrl(url) {
      this._logCall.apply(this, ['setUrl'].concat(Array.prototype.slice.call(arguments)));
      this.navigation.setUrl(url);
    },
    // Config
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method getConfig
     * @return {Object}
     */
    getConfig: function getConfig() {
      this._logCall.apply(this, ['getConfig'].concat(Array.prototype.slice.call(arguments)));
      return this.config;
    },
    // Session
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method getSession
     * @return {Object}
     */
    getSession: function getSession() {
      this._logCall.apply(this, ['getSession'].concat(Array.prototype.slice.call(arguments)));
      return _objectSpread(_objectSpread({}, this.get('session.content')), {}, {
        token: this.get('session.token')
      });
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method validateSession
     * @return {Promise<Boolean>}
     */
    validateSession: function validateSession() {
      this._logCall.apply(this, ['validateSession'].concat(Array.prototype.slice.call(arguments)));
      return this.session.validateSession();
    },
    /**
     * Closes the current App and deletes the Session.
     *
     * @memberof LigaOsApi
     * @instance
     *
     * @method logout
     */
    logout: function logout() {
      this._logCall.apply(this, ['logout'].concat(Array.prototype.slice.call(arguments)));
      this.session.triggerLogout();
    },
    // App state
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method getLanguage
     * @return {String}
     */
    getLanguage: function getLanguage() {
      this._logCall.apply(this, ['getLanguage'].concat(Array.prototype.slice.call(arguments)));
      return this.state.getLanguage();
    },
    /**
     * If the App switches the language this should be called. LIGA OS stores the
     * language token and reloads the browser if needed. The App can than get the
     * current language by calling `getLanguage()`.
     *
     * @memberof LigaOsApi
     * @instance
     *
     * @method setLanguage
     * @param {String} languageCode
     */
    setLanguage: function setLanguage(languageCode) {
      this._logCall.apply(this, ['setLanguage'].concat(Array.prototype.slice.call(arguments)));
      this.state.setLanguage(languageCode);
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method setTitle
     * @param {String} title
     */
    setTitle: function setTitle(title) {
      this._logCall.apply(this, ['setTitle'].concat(Array.prototype.slice.call(arguments)));
      this.state.setAppTitle(title);
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @deprecated
     *
     * @method setTitlePrefix
     * @param {String} prefix
     */
    setTitlePrefix: function setTitlePrefix() {
      var prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      this._logDeprecatedCall.apply(this, [['setTitlePrefix', 'setTitleCount'], '2.3.0'].concat(Array.prototype.slice.call(arguments)));
      if (/^\d+$/.test(prefix)) {
        this.state.setTitleCount(Number(prefix));
      }
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method setTitleCount
     * @param {Number} count
     */
    setTitleCount: function setTitleCount() {
      var count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      this._logCall.apply(this, ['setTitleCount'].concat(Array.prototype.slice.call(arguments)));
      (false && !(/^\d+$/.test(count) && Number(count) >= 0) && Ember.assert('setTitleCount(): count needs to be a number (integer, greater or equal 0).', /^\d+$/.test(count) && Number(count) >= 0));
      this.state.setTitleCount(Number(count) || 0);
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method hideCommunicator
     * @private
     */
    hideCommunicator: function hideCommunicator() {
      this._logCall.apply(this, ['hideCommunicator'].concat(Array.prototype.slice.call(arguments)));
      this.set('communicator.isVisible', false);
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method showCommunicator
     * @private
     */
    showCommunicator: function showCommunicator() {
      this._logCall.apply(this, ['showCommunicator'].concat(Array.prototype.slice.call(arguments)));
      this.set('communicator.isVisible', true);
    },
    // App Events
    /**
     * This should be called by the App whenever a page finished loading. LIGA OS
     * then removes the Overlay and makes the App visible.
     *
     * @memberof LigaOsApi
     * @instance
     *
     * @method notifyAppLoaded
     */
    notifyAppLoaded: function notifyAppLoaded() {
      this._logCall.apply(this, ['notifyAppLoaded'].concat(Array.prototype.slice.call(arguments)));
      this.state.setAppLoaded(true);
    },
    /**
     * Should be called by the app if the current user has no valid session. LIGA
     * OS closes the app and shows the login screen.
     *
     * @memberof LigaOsApi
     * @instance
     *
     * @method notifyInvalidSession
     */
    notifyInvalidSession: function notifyInvalidSession() {
      var _this2 = this;
      this._logCall.apply(this, ['notifyInvalidSession'].concat(Array.prototype.slice.call(arguments)));
      this.session.validateSession().then(function (valid) {
        if (!valid) {
          _this2.session.triggerLogout();
        } else {
          _this2.state.triggerAppError({
            title: 'Access Denied!'.loc(),
            description: 'You are missing necessary rights to access this app.'.loc()
          });
        }
      });
    },
    /**
     * Should be called by the app if the current user has not the necessary
     * rights to access the app. LIGA OS closes the app and shows an error.
     *
     * @memberof LigaOsApi
     * @instance
     *
     * @method notifyAccessDenied
     */
    notifyAccessDenied: function notifyAccessDenied() {
      var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        message = _ref2.message;
      this._logCall.apply(this, ['notifyAccessDenied'].concat(Array.prototype.slice.call(arguments)));
      this.state.triggerAppError({
        title: 'Access Denied!'.loc(),
        description: message || 'You are missing necessary rights to access this app.'
      });
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method setBlur
     * @param {boolean} state
     */
    setBlur: function setBlur(state) {
      this.state.set('isBlurred', state);
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method checkBrowserSupport
     * @param {Array} supportedBrowsers
     * @return {boolean}
     */
    checkBrowserSupport: function checkBrowserSupport(supportedBrowsers) {
      this._logCall.apply(this, ['checkBrowserSupport'].concat(Array.prototype.slice.call(arguments)));
      if (!(0, _browser.isSupportedBrowser)(supportedBrowsers)) {
        var description = (0, _browser.formatSupportedBrowsers)(supportedBrowsers);
        this.state.triggerAppError({
          title: 'Your browser is not supported.'.loc(),
          description: "".concat('Supported browsers are'.loc(), ": ").concat(description)
        });
        return false;
      }
      return true;
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method confirm
     * @param {sting} message
     * @return {boolean}
     */
    confirm: function confirm(message) {
      return window.confirm(message);
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method getTheme
     * @param {object} theme
     * @return {object}
     */
    getTheme: function getTheme() {
      this._logCall.apply(this, ['getTheme'].concat(Array.prototype.slice.call(arguments)));
      return this.get('clientTheme.data');
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method getSetting
     * @param {String} key
     * @param {any} defaultValue
     * @return {Promise<any>} value
     */
    getSetting: function getSetting(key, defaultValue) {
      return this.settings.getValue(key).then(function (value) {
        return Ember.isNone(value) ? defaultValue : value;
      });
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @method getTheme
     * @param {String} key
     * @param {any} value
     * @return {Promise<any>} value
     */
    setSetting: function setSetting(key, value) {
      var _this3 = this;
      this._logCall.apply(this, ['setSetting'].concat(Array.prototype.slice.call(arguments)));
      return this.settings.setValue(key, value).then(function (setting) {
        return _this3.settings.saveSetting(setting);
      });
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @private
     * @method triggerEvent
     * @param {String} event
     */
    triggerEvent: function triggerEvent(event) {
      var iframe = (0, _jquery.default)('.app-frame').get(0);
      if (iframe) {
        iframe.contentWindow.postMessage({
          event: event
        }, '*');
      }
    },
    /**
     * @memberof LigaOsApi
     * @instance
     * @private
     *
     * @method _validateMessageOrigin
     * @param {String} origin
     * @return {Boolean}
     */
    _validateMessageOrigin: function _validateMessageOrigin(origin) {
      var allowedApps = this.navigation.getApps();

      // Trust everything that runs on the same domain
      if (origin === window.location.origin) {
        return true;
      }
      var isAllowed = allowedApps.some(function (app) {
        return app.url.origin.indexOf(origin) === 0;
      });
      return isAllowed;
    },
    /**
     * @memberof LigaOsApi
     * @instance
     *
     * @private
     * @method register
     */
    register: function register() {
      var _this4 = this;
      (0, _jquery.default)(window).on('message.liga-os-api', function (event) {
        var _event$originalEvent = event.originalEvent,
          origin = _event$originalEvent.origin,
          source = _event$originalEvent.source,
          _event$originalEvent$ = _event$originalEvent.data,
          idx = _event$originalEvent$.idx,
          method = _event$originalEvent$.method,
          args = _event$originalEvent$.args;
        var allowedOrigin = _this4._validateMessageOrigin(origin);
        if (!allowedOrigin) {
          console.error('Untrusted origin:', origin);
        }
        if (source && idx && method && allowedOrigin) {
          if (typeof _this4[method] === 'function') {
            Ember.RSVP.resolve(_this4[method].apply(_this4, _toConsumableArray(args || []))).then(function (response) {
              _this4._logResponse(response);
              source.postMessage({
                idx: idx,
                response: response
              }, '*');
            }).catch(function (error) {
              return console.error('[liga-os-api] error', error);
            });
          } else {
            console.error('[liga-os-api] Invalid call', {
              idx: idx,
              method: method,
              args: args
            });
          }
        }
      });
    },
    // Helper functions
    _logCall: function _logCall(func) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      if (!this.get('config.log.ligaOSApi.call')) {
        return;
      }
      args = args.filter(function (arg) {
        return typeof arg !== 'function';
      });
      if (args.length > 0) {
        /* eslint-disable no-console */
        console[LOG_GROUP_FUNC]("%c[liga-os-api] ".concat(func, "()"), "color:".concat(LOG_COLOR, ";"));
        args.forEach(function (arg) {
          return console.log(arg);
        });
        console.groupEnd('');
      } else {
        console.log("%c[liga-os-api] ".concat(func, "()"), "color:".concat(LOG_COLOR, "; font-weight: bold;"));
        /* eslint-enable no-console */
      }
    },
    _logResponse: function _logResponse(response) {
      if (!this.get('config.log.ligaOSApi.response')) {
        return;
      }
      if (response) {
        /* eslint-disable no-console */
        console[LOG_GROUP_FUNC]('%c=>', "color: ".concat(LOG_COLOR, "; font-weight: bold;"));
        console.log(response);
        console.groupEnd();
        /* eslint-enable no-console */
      }
    },
    _logDeprecatedCall: function _logDeprecatedCall(funcArg, until) {
      if (!this.get('config.log.ligaOSApi.deprecated')) {
        return;
      }
      if (typeof funcArg === 'string') {
        funcArg = [funcArg];
      }
      var _funcArg = funcArg,
        _funcArg2 = _slicedToArray(_funcArg, 2),
        func = _funcArg2[0],
        funcReplace = _funcArg2[1];
      for (var _len2 = arguments.length, args = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
        args[_key2 - 2] = arguments[_key2];
      }
      this._logCall.apply(this, [func].concat(args));
      (false && !(null) && Ember.deprecate("[liga-os-api] ".concat(func, "() is deprecated ").concat(funcReplace ? " use ".concat(funcReplace, "() instead") : '', "."), null, {
        id: "liga-os-api:".concat(func.replace(/([A-Z][a-z]+|[a-z]+)([A-Z])/g, '$1-$2').toLowerCase()),
        until: until
      }));
    }
  });
});
