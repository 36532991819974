define("ln-liga-os/controllers/application", ["exports", "ln-liga-os/utils/browser", "ln-liga-os/utils/inject-config"], function (_exports, _browser, _injectConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    state: Ember.inject.service(),
    clientTheme: Ember.inject.service(),
    microMenu: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      // TODO is there a better solution for this?
      // Set Class `resizing` to disable animations on resizing
      var resizeTimer;
      window.addEventListener('resize', Ember.run.bind(this, function () {
        clearTimeout(resizeTimer);
        document.body.classList.add('resizing');
        resizeTimer = setTimeout(function () {
          return document.body.classList.remove('resizing');
        }, 10);
      }));
      var onOrientationChange = function onOrientationChange() {
        // Set the body height, so that mobile browsers don't overlay content with
        // their UI.
        document.body.style.height = '100vh';
        if (window.innerHeight !== document.body.offsetHeight) {
          document.body.style.height = window.innerHeight;
        }
      };
      window.addEventListener('resize', onOrientationChange);
      window.addEventListener('orientationchange', onOrientationChange);
      onOrientationChange();
    },
    config: (0, _injectConfig.default)(),
    browserIsSupported: Ember.computed(function () {
      var browsers = this.get('config.supportedBrowsers');
      return !browsers || (0, _browser.isSupportedBrowser)(browsers);
    }),
    browserSupportedMsg: Ember.computed(function () {
      var browsers = this.get('config.supportedBrowsers') || [];
      return (0, _browser.formatSupportedBrowsers)(browsers);
    })
  });
});
