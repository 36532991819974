define("ln-liga-os/services/api", ["exports", "ln-ember-api-service/services/api"], function (_exports, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _api.default.extend({
    session: Ember.inject.service(),
    loadToken: function loadToken() {
      return this.get('session').token;
    }
  });
});
