define("ln-liga-os/models/vdc/plan-template", ["exports", "ln-ember-data-models/models/vdc/plan-template"], function (_exports, _planTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _planTemplate.default;
    }
  });
});
