define("ln-liga-os/components/startup-mask", ["exports", "ln-liga-os/utils/inject-config"], function (_exports, _injectConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    state: Ember.inject.service(),
    clientTheme: Ember.inject.service(),
    classNames: ['startup-mask-component'],
    classNameBindings: ['show', 'session.isLoggedIn:logged-in', 'clientTheme.className', 'state.appLoaded:app-loaded:app-not-loaded'],
    config: (0, _injectConfig.default)()
  });
});
