define("ln-liga-os/models/vdc/cost-rule-condition", ["exports", "ln-ember-data-models/models/vdc/cost-rule-condition"], function (_exports, _costRuleCondition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _costRuleCondition.default;
    }
  });
});
