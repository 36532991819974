define("ln-liga-os/models/vdc/fragments/object-area", ["exports", "ln-ember-data-models/models/vdc/fragments/object-area"], function (_exports, _objectArea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _objectArea.default;
    }
  });
});
