define("ln-liga-os/services/logger", ["exports", "ln-ember-toolkit/services/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _logger.default;
    }
  });
});
