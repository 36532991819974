define("ln-liga-os/services/api-events", ["exports", "ln-ember-api-service/services/api-events"], function (_exports, _apiEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _apiEvents.default;
    }
  });
});
