define("ln-liga-os/services/navigation", ["exports", "ember-inflector", "ln-ember-toolkit/utils/regexp", "ln-liga-os/utils/url", "ln-liga-os/utils/inject-config"], function (_exports, _emberInflector, _regexp, _url2, _injectConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
  function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function replaceUrlPlaceholders(url) {
    return url.replace('{host}', window.location.hostname);
  }
  function stripFeatureBranchName(url) {
    return url.replace(/(-\w+)?(-\d+)(\/?)/, '$3');
  }
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    api: Ember.inject.service(),
    state: Ember.inject.service(),
    session: Ember.inject.service(),
    config: (0, _injectConfig.default)(),
    store: Ember.inject.service(),
    navigations: null,
    currentUrl: null,
    _placeholders: null,
    currentItem: Ember.computed('currentUrl', 'navigations.[]', function () {
      return this.getItemFor(this.currentUrl);
    }),
    currentApp: Ember.computed('currentUrl', function () {
      return this.getAppFor(this.currentUrl);
    }),
    // This is supposed to be needed in the development only
    // in order to map ln-test URLs provided by API to the locally served apps
    urlReplaces: Ember.computed('config.urlReplaces', function () {
      var urlReplaces = this.get('config.urlReplaces') || {};
      Object.keys(urlReplaces).forEach(function (key) {
        urlReplaces[key] = replaceUrlPlaceholders(urlReplaces[key]);
      });
      return urlReplaces;
    }),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.set('_placeholders', {});
      this.session.on('did-logout', function () {
        _this.reset();
      });
    },
    reset: function reset() {
      this.set('navigations', null);
      this.set('currentUrl', null);
    },
    loadNavigations: function loadNavigations() {
      var _this2 = this;
      var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      if (!force && this.navigations) {
        return Ember.RSVP.resolve();
      }
      return this.api.read('myliga', ['navigations']) // Should also use the Myliga API, but there is a problem on PI prod
      .then(function (response) {
        _this2.set('navigations', _this2._cleanUpNavigations(response.result));
        return Ember.RSVP.resolve();
      });
    },
    isAllowedAppUrl: function isAllowedAppUrl(url) {
      var app = this.getAppFor(url);
      return Boolean(app && !app.get('disabled'));
    },
    loadedAppCheck: function loadedAppCheck() {
      var _this3 = this;
      if (!this.get('state.appLoaded')) {
        if (this.get('config.disable.loadedAppCheck')) {
          return this.state.setAppLoaded(true);
        }
        Ember.run.cancel(this.lastLoadedAppCheck);
        this.set('lastLoadedAppCheck', Ember.run.later(function () {
          if (!_this3.get('session.isLoggedIn')) {
            return;
          }
          if (!_this3.get('state.appLoaded')) {
            if (_this3.get('config.disable.loadedAppCheckError')) {
              console.error('App not loaded: The App could not be loaded properly.');
              return _this3.state.setAppLoaded(true);
            }
            _this3.state.triggerAppError({
              title: 'App not loaded!'.loc(),
              description: 'The App could not be loaded properly.'.loc()
            });
          }
        }, this.get('config.loadedAppCheckTime') || 3000));
      }
    },
    belongsToCurrentApp: function belongsToCurrentApp(url) {
      return this.getItemFor(this._cleanUrl(url)) === this.currentItem;
    },
    belongsToExternalApp: function belongsToExternalApp(url) {
      var app = this.getAppFor(url);
      return Boolean(app && !app.get('slug'));
    },
    getItemFor: function getItemFor(url) {
      if (!url || !this.navigations) {
        return;
      }
      if (this.get('config.allow_feature_branches')) {
        url = stripFeatureBranchName(url);
      }
      return this.navigations.toArray().sort(function (a, b) {
        return b.url.length - a.url.length;
      }).find(function (nav) {
        var navUrl = nav.url.replace(new RegExp(/\/?#?\/$/), '');
        return new RegExp("".concat((0, _regexp.escapeExpStr)(navUrl), "($|#|/|\\?)")).test(url);
      });
    },
    getApps: function getApps() {
      var _this4 = this;
      var apps = (this.get('config.apps') || []).filter(function (app) {
        return app.url;
      }).map(function (app) {
        var urlString = replaceUrlPlaceholders(app.url);
        // if the `urlString` is fully qualified URL
        // the second argument is being ignored
        var url = new URL(urlString, location.origin);
        var path = urlString.startsWith('/') ? "".concat(url.pathname) : "".concat(url.host).concat(url.pathname);
        var search = url.search || '';
        var hash = url.hash || '';

        // matches incoming URL strings with a proper navigation item config
        var urlMatcher = new RegExp("/?".concat((0, _regexp.escapeExpStr)((path + search).replace(/\/$/, '')), "(#|/|$)"));

        // @todo: `url_path` is some workaround mechanism
        // let's make sure we don't have it on API and remove this codepath
        var navItem = (_this4.navigations || []).find(function (item) {
          return urlMatcher.test(item.url);
        });
        var slug = navItem && navItem.url_path ? navItem.url_path : app.slug;
        return Ember.Object.create(_objectSpread(_objectSpread({}, app), {}, {
          urlMatcher: urlMatcher,
          url: url,
          path: path.replace(/^\//, '').replace(/\/$/, ''),
          search: search,
          hash: hash,
          slug: slug
        }));
      });
      return apps;
    },
    getAppFor: function getAppFor(url) {
      url = this._cleanUrl(url);
      if (this.get('config.allow_feature_branches')) {
        url = stripFeatureBranchName(url);
      }
      return this.getApps().find(function (app) {
        return app.urlMatcher.test(url);
      });
    },
    openUrl: function openUrl(url) {
      if (this.belongsToExternalApp(url)) {
        this.set('state.applicationsError', null);
        var newWindow = window.open(url, '_blank');

        // detect popup blocker
        if (!newWindow || newWindow.closed || Ember.isNone(newWindow.closed)) {
          this.set('state.applicationsError', 'Popup blocked! Please allow popups.'.loc());
          return false;
        }
        return true;
      }
      if (!this.belongsToCurrentApp(url)) {
        this.state.resetTitle();
        this.state.setAppLoaded(false);
      }
      url = this._cleanUrl(url);
      if (this.setUrl(url)) {
        this.trigger('open-url', url);
        return true;
      }
      return false;
    },
    setUrl: function setUrl(url) {
      this.resetAppError();
      url = this._cleanUrl(url);
      if (!this.isAllowedAppUrl(url)) {
        // eslint-disable-next-line no-console
        console.warn("Is not an allowed app URL: ".concat(url));
        this.state.triggerAppError({
          title: 'Access Denied!'.loc(),
          description: 'The App is not a valid LIGA OS app.'.loc()
        });
        return false;
      }
      if (!this.getItemFor(url)) {
        this.state.triggerAppError({
          title: 'Access Denied!'.loc(),
          description: 'You are missing necessary rights to access this app.'
        });
        return false;
      }
      this.set('currentUrl', url);
      this.trigger('url-did-change', url);
      return true;
    },
    resetAppError: function resetAppError() {
      if (this.get('state.appError')) {
        this.state.triggerAppErrorReset();
      }
    },
    isCurrentUrl: function isCurrentUrl(url) {
      return this.currentUrl === url;
    },
    isCurrentItem: function isCurrentItem(item) {
      return this.currentUrl === Ember.get(item, 'url');
    },
    findFirstItem: function findFirstItem(items) {
      return items.find(function (item) {
        return item.url;
      });
    },
    openFirstItem: function openFirstItem() {
      var _ref = this.findFirstItem(this.navigations) || {},
        url = _ref.url;
      this.openUrl(url);
    },
    openResource: function openResource(resource, resourceId) {
      var _this5 = this;
      (false && !(this.canOpenResource(resource, resourceId)) && Ember.assert('Provide resource (string), resourceId (number)', this.canOpenResource(resource, resourceId)));
      return this._buildResourceUrl(resource, resourceId).then(function (url) {
        return _this5.openUrl(url);
      });
    },
    canOpenResource: function canOpenResource(resource, resourceId) {
      if (resource === 'Message') {
        return true;
      }
      return Boolean(!Ember.isNone(resourceId) && !isNaN(Number(resourceId)) && Ember.typeOf(resource) === 'string' && this._getResourceUrlTemplate(resource));
    },
    _buildResourceUrl: function _buildResourceUrl(resource, resourceId) {
      var _this6 = this;
      return this._loadSegments(resource, resourceId).then(function (_ref2) {
        var resource = _ref2.resource,
          segments = _ref2.segments;
        var urlTemplate = _this6._getResourceUrlTemplate(resource);
        var url = _this6._fillPlaceholders(urlTemplate, segments);
        url = _this6.removeSlug(url);
        url = (0, _url2.decodeHashPart)(url);
        url = (0, _regexp.replaceAll)(url, _this6.urlReplaces);
        return Ember.RSVP.resolve(url);
      });
    },
    removeSlug: function removeSlug(url) {
      var ticketId = this.get('config.allow_feature_branches') ? '((-\\w+)?(-\\d+))?' : '';
      this.getApps().filter(function (_ref3) {
        var slug = _ref3.slug;
        return slug;
      }).forEach(function (app) {
        var find = new RegExp("^(!//?)".concat((0, _regexp.escapeExpStr)(app.slug), "(").concat(ticketId, ")($|/(?<appPath>.*|$))"));
        var matches = url.match(find);
        if (matches) {
          var basePath = app.path;
          var protocol = matches[1] || ''; // "!//" or "!/"
          var ticketHandle = matches[2] || '';
          var appPath = matches.groups.appPath;
          url = "".concat(protocol).concat(basePath).concat(ticketHandle, "/").concat(app.search).concat(app.hash).concat(appPath);
        }
      });
      return url;
    },
    addSlug: function addSlug(url) {
      var ticketId = this.get('config.allow_feature_branches') ? '((-\\w+)?(-\\d+))?' : '';
      this.getApps().filter(function (_ref4) {
        var slug = _ref4.slug;
        return slug;
      }).forEach(function (app) {
        var basePath = (0, _regexp.escapeExpStr)(app.path);
        var search = (0, _regexp.escapeExpStr)(app.search.replace('?', '|'));
        var find = new RegExp("^(!//?)".concat(basePath, "(").concat(ticketId, "/)").concat(search, "([|]{2})?/?(?<appPath>.*|$)"));
        var matches = url.match(find);
        if (matches) {
          var protocol = matches[1];
          var ticketHandle = matches[2];
          var appPath = matches.groups.appPath;
          url = "".concat(protocol).concat(app.slug).concat(ticketHandle).concat(appPath);
        }
      });
      return url;
    },
    _fillPlaceholders: function _fillPlaceholders(url) {
      var segments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      url = url || '';
      this._extractPlaceholders(url).forEach(function (placeholder) {
        var segment = segments[placeholder] || '';
        url = url.replace(":".concat(placeholder), segment);
      });
      return url;
    },
    _extractPlaceholders: function _extractPlaceholders(url) {
      if (!this._placeholders[url]) {
        this._placeholders[url] = (String(url).match(/:([^/\d][^/|&]*)/g) || []).map(function (placeholder) {
          return placeholder.replace(/^:/, '');
        });
      }
      return this._placeholders[url];
    },
    _getResourceUrlTemplate: function _getResourceUrlTemplate(resource) {
      return this.get("config.resourceUrlTemplates.".concat(Ember.String.classify(resource)));
    },
    _loadSegments: function _loadSegments(resource, resourceId) {
      var func = "_load".concat(Ember.String.classify(resource), "Segments");
      if (Ember.typeOf(this[func]) === 'function') {
        return this[func](resourceId);
      }

      /*
       * All the placeholders in config/targets (resourceUrlTemplates)
       * are lowercase, and since we use 'context_type' (which comes from API capitalized) for
       * segment/placeholder processing, there is an issue when trying to access the segment.
       *
       * API:
       * {
       *  ...
       *  context_type: 'Pin'
       *  context_id: 3147
       *  ...
       * }
       *
       * APP:
       * segment object:
       * {
       *  pin: 3147
       * }
       */
      var resourceVal = resource.toLowerCase();
      return Ember.RSVP.resolve({
        segments: _defineProperty({}, resourceVal, resourceId),
        resource: resource
      });
    },
    _loadArticleReviewSegments: function _loadArticleReviewSegments(articleReviewId) {
      var resource = 'ArticleReview';
      return this.api.read('vdc', ['article_reviews', articleReviewId]).then(function (articleReview) {
        var segments = {
          project: articleReview.project_id,
          country: articleReview.country_id
        };
        return Ember.RSVP.resolve({
          resource: resource,
          segments: segments
        });
      });
    },
    _loadStoreSegments: function _loadStoreSegments(storeId) {
      var segments = {};
      return this.api.read('vdc', ['stores', storeId]).then(function (store) {
        segments.store = store.id;
        segments.client = store.client_id;
        return Ember.RSVP.resolve({
          segments: segments,
          resource: 'Store'
        });
      });
    },
    _loadOrderSegments: function _loadOrderSegments(orderId) {
      var _this7 = this;
      var segments = {};
      return this.api.read('vdc', ['orders', orderId]).then(function (order) {
        segments.order = order.id;
        return _this7.api.read('vdc', ['projects', order.project_id]);
      }).then(function (project) {
        segments.project = project.id;
        return _this7.api.read('vdc', ['clients', project.client_id]);
      }).then(function (client) {
        segments.client = client.id;
        return Ember.RSVP.resolve({
          resource: 'Order',
          segments: segments
        });
      });
    },
    _loadAssemblySegments: function _loadAssemblySegments(assemblyId) {
      var _this8 = this;
      var segments = {};
      return this.api.read('vdc', ['assemblies', assemblyId]).then(function (assembly) {
        segments.assembly = assembly.id;
        return _this8.api.read('vdc', ['projects', assembly.project_id]);
      }).then(function (project) {
        segments.project = project.id;
        return _this8.api.read('vdc', ['clients', project.client_id]);
      }).then(function (client) {
        segments.client = client.id;
        return Ember.RSVP.resolve({
          resource: 'Assembly',
          segments: segments
        });
      });
    },
    _loadProjectSegments: function _loadProjectSegments(projectId) {
      return this.api.read('vdc', ['projects', projectId]).then(function (project) {
        var segments = {
          project: project.get('id'),
          client: project.get('client_id')
        };
        return Ember.RSVP.resolve({
          resource: 'Project',
          segments: segments
        });
      });
    },
    _loadProjectTaskSegments: function _loadProjectTaskSegments(taskId) {
      var _this9 = this;
      var segments = {};
      return this.api.read('vdc', ['tasks', taskId]).then(function (task) {
        segments.task = task.id;
        return _this9.api.read('vdc', ['projects', task.context_id]);
      }).then(function (project) {
        segments.project = project.id;
        segments.client = project.client_id;
        return Ember.RSVP.resolve({
          resource: 'ProjectTask',
          segments: segments
        });
      });
    },
    _loadEventTaskSegments: function _loadEventTaskSegments(taskId) {
      var _this10 = this;
      var segments = {};
      return this.api.read('vdc', ['tasks', taskId]).then(function (task) {
        segments.task = task.id;
        return _this10.api.read('vdc', ['events', task.context_id]);
      }).then(function (event) {
        segments.event = event.id;
        segments.client = event.client_id;
        return Ember.RSVP.resolve({
          resource: 'EventTask',
          segments: segments
        });
      });
    },
    _loadMessageSegments: function _loadMessageSegments(messageId) {
      var _this11 = this;
      var segments = {};
      return this.api.read('vdc', ['messages', messageId]).then(function (message) {
        var contextType = message.context_type;
        segments.message = message.id;
        if (!['Project', 'Event'].includes(contextType)) {
          return Ember.RSVP.reject();
        }
        var resource = (0, _emberInflector.pluralize)(contextType.toLowerCase());
        return _this11.api.read('vdc', [resource, message.context_id]).then(function (resource) {
          return {
            resource: resource,
            resourceName: contextType
          };
        });
      }).then(function (_ref5) {
        var resource = _ref5.resource,
          resourceName = _ref5.resourceName;
        segments[resourceName.toLowerCase()] = resource.id;
        segments.client = resource.client_id;
        return Ember.RSVP.resolve({
          segments: segments,
          resource: resourceName === 'Project' ? 'ProjectMessage' : 'EventMessage'
        });
      });
    },
    _cleanUrl: function _cleanUrl(url) {
      var _url = url || '';
      _url = (0, _url2.cleanUrl)(_url);
      _url = (0, _url2.cleanAppUrl)(_url);
      _url = (0, _regexp.replaceAll)(_url, this.urlReplaces);
      return _url;
    },
    _cleanUpNavigations: function _cleanUpNavigations(navigations) {
      var _this12 = this;
      if (!navigations) {
        return [];
      }
      return navigations.sortBy('position').map(function (navigation) {
        return _this12._cleanUpNavigation(navigation);
      }).filter(function (navigation) {
        var allowed = _this12.isAllowedAppUrl(navigation.url);
        if (!allowed) {
          // eslint-disable-next-line no-console
          console.warn('Not an allowed navigation item', navigation);
        }
        return allowed;
      });
    },
    _cleanUpNavigation: function _cleanUpNavigation(navigation) {
      navigation.url = (0, _regexp.replaceAll)(navigation.url, this.urlReplaces);
      return navigation;
    }
  });
});
