define("ln-liga-os/components/communicator/filter-notifications", ["exports", "ln-liga-os/components/communicator/filter-base"], function (_exports, _filterBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _filterBase.default.extend({
    classNames: ['communicator-filter-notifications-component'],
    defaultFilter: 'current',
    filters: null,
    init: function init() {
      this.set('filters', [{
        key: 'current',
        label: 'Inbox'.loc()
      }, {
        key: 'flagged',
        label: 'Flagged'.loc()
      }, {
        key: 'unread',
        label: 'Unread'.loc()
      }, {
        key: 'archive',
        label: 'Archive'.loc()
      }]);
      this._super.apply(this, arguments);
    }
  });
});
