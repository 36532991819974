define("ln-liga-os/models/vdc/revision", ["exports", "ln-ember-data-models/models/vdc/revision"], function (_exports, _revision) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _revision.default;
    }
  });
});
