define("ln-liga-os/controllers/login", ["exports", "ln-liga-os/utils/inject-config"], function (_exports, _injectConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    router: Ember.inject.service(),
    api: Ember.inject.service(),
    session: Ember.inject.service(),
    clientTheme: Ember.inject.service(),
    storage: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    username: null,
    password: null,
    message: null,
    invalid: false,
    btnInvalid: false,
    showPasswordInput: false,
    showPasswordReset: false,
    showPasswordResetRequested: false,
    config: (0, _injectConfig.default)(),
    passwordInvalid: Ember.computed.and('showPasswordReset', 'invalid', 'btnInvalid'),
    loginInvalid: Ember.computed.and('showLogin', 'invalid', 'btnInvalid'),
    passwordInputType: Ember.computed('showPasswordInput', function () {
      return this.showPasswordInput ? 'text' : 'password';
    }),
    showLogin: Ember.computed('showPasswordReset', 'showPasswordResetRequested', function () {
      return !this.showPasswordReset && !this.showPasswordResetRequested;
    }),
    loggedIn: Ember.computed('session.isLoggedIn', function () {
      return this.get('session.isLoggedIn');
    }),
    actions: {
      resetErrorState: function resetErrorState() {
        this.set('btnInvalid', false);
      },
      login: function login() {
        var _this = this;
        this.resetMessage();
        this.session.auth({
          password: this.password || '',
          username: this.username || ''
        }).then(function () {
          _this.resetMessage();
          _this.router.transitionTo('/!');
        }).catch(function (err) {
          var msg = Ember.get(err, 'message') || 'Login failed!';
          _this.setError(msg);
        });
        return false;
      },
      togglePasswordReset: function togglePasswordReset() {
        this.resetMessage();
        this.set('password', null);
        this.toggleProperty('showPasswordReset');
      },
      goToLogin: function goToLogin() {
        this.setProperties({
          username: '',
          showPasswordReset: false,
          showPasswordResetRequested: false
        });
      },
      passwordReset: function passwordReset() {
        var _this2 = this;
        this.resetMessage();
        this.api.create('myliga', ['security', 'password_resets'], {
          email_or_username: this.username
        }).then(function () {
          _this2.set('showPasswordReset', false);
          _this2.set('showPasswordResetRequested', true);
        }).catch(function (error) {
          _this2.flashMessages.addErrorMessages(error);
        });
      },
      togglePasswordIcon: function togglePasswordIcon() {
        this.toggleProperty('showPasswordInput');
      }
    },
    keycloakLogin: Ember._action(function () {
      return this.session.auth();
    }),
    setError: function setError(msg) {
      this.set('invalid', true);
      this.set('btnInvalid', true);
      this.set('message', msg);
    },
    resetMessage: function resetMessage() {
      this.set('invalid', false);
      this.set('btnInvalid', false);
      this.set('message', null);
    }
  });
});
