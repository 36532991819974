define("ln-liga-os/components/communicator/filter-base", ["exports", "ln-liga-os/templates/components/communicator/filter-base"], function (_exports, _filterBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  var _default = _exports.default = Ember.Component.extend({
    layout: _filterBase.default,
    classNames: ['communicator-filter-base-component'],
    filters: null,
    hrStyle: Ember.computed('filterIdx', 'filters.length', function () {
      var x = this.filterIdx * 100;
      var width = 100 / this.get('filters.length');
      return "\n      width: ".concat(width, "%;\n      transform: translateX(").concat(x, "%);\n    ").htmlSafe();
    }),
    filterIs: Ember.computed('filter', function () {
      return _defineProperty({}, this.filter || '', true);
    }),
    filterIdx: Ember.computed('filters.[]', 'filter', function () {
      var key = this.filter;
      return (this.filters || []).findIndex(function (filter) {
        return filter.key === key;
      });
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      if (!this.filter && this.defaultFilter) {
        Ember.run.scheduleOnce('afterRender', function () {
          _this.set('filter', _this.defaultFilter);
        });
      }
    },
    actions: {
      filter: function filter(filterKey) {
        this.set('filter', filterKey);
      }
    }
  });
});
