define("ln-liga-os/objects/communicator-tab-item", ["exports", "ln-ember-toolkit/utils/string-utils", "ln-liga-os/utils/inject-config"], function (_exports, _stringUtils, _injectConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.extend({
    rights: Ember.inject.service(),
    state: Ember.inject.service(),
    config: (0, _injectConfig.default)(),
    communicator: Ember.inject.service(),
    identifier: null,
    activateController: false,
    hasRight: false,
    name: Ember.computed('identifier', function () {
      return (0, _stringUtils.toLabel)(this.identifier);
    }),
    title: Ember.computed('identifier', function () {
      if (this.identifier === 'settings') {
        return this.get('state.user.fullName');
      }

      // return this.name.loc();
      return Ember.String.loc(this.name);
    }),
    hasAccess: Ember.computed('identifier', 'hasRight', function () {
      return this.hasRight && !this.get("config.disable.communicator_".concat(this.identifier));
    }),
    badge: Ember.computed('communicator.badges', function () {
      return this.get("communicator.badges.".concat(this.identifier));
    }),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      if (!this.hasRight) {
        var identifier = this.identifier;
        this.rights.can('OTHER', "ligaos.communicator.".concat(identifier, ".read")).then(function (permission) {
          _this.set('hasRight', permission);
        });
      }
    }
  });
});
