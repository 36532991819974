define("ln-liga-os/routes/application", ["exports", "ln-liga-os/utils/inject-config"], function (_exports, _injectConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    state: Ember.inject.service(),
    api: Ember.inject.service(),
    apiEvents: Ember.inject.service(),
    navigation: Ember.inject.service(),
    ligaOsApi: Ember.inject.service(),
    clientTheme: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.state.on('app-loaded', function (loaded) {
        if (loaded) {
          document.querySelector('body').classList.add('loaded');
          document.querySelector('body').classList.add('app-loaded');
        } else {
          document.querySelector('body').classList.remove('app-loaded');
        }
      });
      this.apiEvents.triggerConfigChange(this.config);
      this.ligaOsApi.register();
      if (this.get('config.exposeLigaOSApi')) {
        window.LIGAOS_API = this.ligaOsApi;
      }
      if (this.get('config.exposeApi')) {
        window.API = this.api;
      }
    },
    config: (0, _injectConfig.default)(),
    beforeModel: function beforeModel() {
      var _this = this;
      return this.clientTheme.load().then(function () {
        return _this.state.restore();
      });
    },
    model: function model() {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      var langCode = this.ligaOsApi.getLanguage();
      Ember.STRINGS = config.STRINGS[langCode] || {};
    }
  });
});
