define("ln-liga-os/transforms/string-array", ["exports", "ln-ember-data-models/transforms/string-array"], function (_exports, _stringArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _stringArray.default;
    }
  });
});
