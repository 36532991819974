define("ln-liga-os/components/contact-list/item-absence", ["exports", "ln-liga-os/components/contact-list/item-base"], function (_exports, _itemBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _itemBase.default.extend({
    classNames: ['contact-list-item-absence-component'],
    contact: Ember.computed.alias('item.contact')
  });
});
