define("ln-liga-os/utils/normalize-error-response", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = normalizeErrorResponse;
  function normalizeErrorResponse(error) {
    var message = null;
    var details = (Ember.get(error, 'details') || []).filter(function (_ref) {
      var reference = _ref.reference;
      return reference;
    }).reduce(function (errors, _ref2) {
      var message = _ref2.message,
        reference = _ref2.reference;
      errors[reference.replace(/^obj\./, '')] = message;
      return errors;
    }, {});
    if (Object.keys(details).length === 0) {
      var messages = (Ember.get(error, 'details') || []).filter(function (_ref3) {
        var reference = _ref3.reference,
          message = _ref3.message;
        return !reference && message;
      }).map(function (_ref4) {
        var message = _ref4.message;
        return message;
      });
      message = [Ember.get(error, 'message')].concat(messages).join('\n');
    }
    return {
      message: message,
      details: details
    };
  }
});
