define("ln-liga-os/services/state", ["exports", "@sentry/browser", "ember-data", "ln-liga-os/utils/set-if-changed", "ln-liga-os/utils/inject-config"], function (_exports, Sentry, _emberData, _setIfChanged, _injectConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PromiseArray = _emberData.default.PromiseArray;
  var _default = _exports.default = Ember.Service.extend(Ember.Evented, {
    apiEvents: Ember.inject.service(),
    storage: Ember.inject.service(),
    config: (0, _injectConfig.default)(),
    settings: Ember.inject.service(),
    store: Ember.inject.service(),
    rights: Ember.inject.service(),
    communicator: Ember.inject.service(),
    clientTheme: Ember.inject.service(),
    appLoaded: false,
    appLocation: false,
    session: null,
    showClickCatcherMask: false,
    showStartupMask: true,
    showLoaderMask: Ember.computed.not('appLoaded'),
    notificationsCount: Ember.computed('communicator.badges', function () {
      return (Number(this.get('communicator.badges.notifications')) || 0) + (Number(this.get('communicator.badges.tasks')) || 0);
    }),
    titleCount: Ember.computed('communicator.badgeNumber', 'appCount', 'notificationsCount', function () {
      if (this.appCount) {
        return this.appCount + (this.notificationsCount || 0);
      }
      return this.communicator.badgeNumber;
    }),
    languages: Ember.computed(function () {
      return PromiseArray.create({
        promise: this.store.query('myliga/language', {
          active: true,
          native_spelling: true
        })
      });
    }),
    updateTitleCount: Ember.observer('titleCount', function () {
      this.updateTitle();
    }),
    updateShowStartupMask: Ember.observer('appLoaded', 'session', function () {
      if (!this.session) {
        this.set('showStartupMask', true);
      }
      if (this.showStartupMask && this.appLoaded) {
        this.set('showStartupMask', false);
      }
    }),
    reset: function reset() {
      this.set('user', null);
      this.set('appError', null);
      this.set('appLoaded', false);
      this.set('appTitle', null);
      this.set('appCount', 0);
      this.communicator.reset();
      this.rights.reset();
      this.updateTitle();
    },
    resetTitle: function resetTitle() {
      this.set('appCount', 0);
      this.set('appTitle', null);
    },
    // Called in application route
    restore: function restore() {
      this.setSession(this.storage.get('ln-liga-os:session', null));
      this.apiEvents.triggerLanguageChange(this.getLanguage());
      this.updateStrings();
      this.updateTitle();
    },
    loadUser: function loadUser() {
      var _this = this;
      var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      if (!force && Number(this.get('user.id')) === this.get('session.user_id')) {
        return Ember.RSVP.resolve();
      }
      if (Ember.isNone(this.get('session.user_id'))) {
        return Ember.RSVP.reject(new Error('session.user_id is not set'));
      }
      return this.store.findRecord('myliga/user', this.get('session.user_id')).then(function (user) {
        return _this.set('user', user);
      }).then(function () {
        return _this.languages;
      }).then(function (languages) {
        var language = languages.findBy('id', String(_this.get('user.language_id')));
        if (language) {
          _this.setLanguage(language.get('code'), false);
        }
      });
    },
    setSession: function setSession(session) {
      if ((0, _setIfChanged.default)(this, 'session', session)) {
        this.storage.set('ln-liga-os:session', session);
        this.apiEvents.triggerSessionChange(session);

        // Set Sentry user context
        if (session) {
          Sentry.configureScope(function (scope) {
            scope.setUser({
              id: "".concat(session.user_id)
            });
          });
        }
      }
    },
    setLanguage: function setLanguage(code) {
      var reload = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      if (code !== this.getLanguage()) {
        this.storage.set('ln-liga-os:language', code);
        this.apiEvents.triggerLanguageChange(code);
        this.updateStrings();
        if (reload) {
          this.trigger('language-did-change', code);
        }
      }
    },
    getLanguage: function getLanguage() {
      return this.storage.get('ln-liga-os:language') || this.detectLanguage();
    },
    setAppLoaded: function setAppLoaded(appLoaded) {
      if ((0, _setIfChanged.default)(this, 'appLoaded', appLoaded)) {
        this.trigger('app-loaded', appLoaded);
      }
    },
    setAppTitle: function setAppTitle() {
      var appTitle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      // TODO remove appTitle on app switch!
      if ((0, _setIfChanged.default)(this, 'appTitle', appTitle)) {
        this.updateTitle();
      }
    },
    setTitleCount: function setTitleCount() {
      var count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      // TODO remove prefix on app switch!
      if ((0, _setIfChanged.default)(this, 'appCount', count)) {
        this.updateTitle();
      }
    },
    updateTitle: function updateTitle() {
      var title = this.get('clientTheme.data.title') || 'LIGAOS';
      if (this.appTitle) {
        title = "".concat(this.appTitle, " - ").concat(title);
      }
      if (this.titleCount) {
        title = "(".concat(this.titleCount, ") ").concat(title);
      }
      document.querySelector('title').innerHTML = title;
      document.title = title;
    },
    showClickCatcher: function showClickCatcher() {
      this.set('showClickCatcherMask', true);
      this.trigger('show-click-catcher');
    },
    hideClickCatcher: function hideClickCatcher() {
      if (this.showClickCatcherMask) {
        this.set('showClickCatcherMask', false);
        this.trigger('hide-click-catcher');
      }
    },
    toogleNavigationIsOpen: function toogleNavigationIsOpen() {
      this.setNavigationIsOpen(!this.navigationIsOpen);
    },
    setNavigationIsOpen: function setNavigationIsOpen(open) {
      if ((0, _setIfChanged.default)(this, 'navigationIsOpen', open)) {
        if (open) {
          this.showClickCatcher();
        } else {
          this.hideClickCatcher();
        }
      }
      // TODO trigger event?
    },
    toogleCommunicatorIsOpen: function toogleCommunicatorIsOpen() {
      this.setCommunicatorIsOpen(!this.communicatorIsOpen);
    },
    setCommunicatorIsOpen: function setCommunicatorIsOpen(open) {
      if ((0, _setIfChanged.default)(this, 'communicatorIsOpen', open)) {
        if (open) {
          this.showClickCatcher();
        } else {
          this.hideClickCatcher();
        }
      }
      // TODO trigger event?
    },
    propertyHasChanged: function propertyHasChanged(key, value) {
      return JSON.stringify(this.get(key)) !== JSON.stringify(value);
    },
    triggerNotificationSound: function triggerNotificationSound() {
      var _this2 = this;
      this.settings.getValue('NotificationSound').then(function (enabled) {
        if (enabled) {
          _this2.trigger('notification-sound');
        }
      });
    },
    triggerAppErrorReset: function triggerAppErrorReset() {
      if (this.appError) {
        this.set('appError', null);
        this.trigger('app-error-reset');
      }
    },
    triggerAppError: function triggerAppError(error) {
      this.set('appError', error);
      this.trigger('app-error', error);
      this.setAppLoaded(true);
    },
    detectLanguage: function detectLanguage() {
      var _ref = window.navigator || {},
        _ref$languages = _ref.languages,
        languages = _ref$languages === void 0 ? [] : _ref$languages;
      var _Ember$getOwner$resol = Ember.getOwner(this).resolveRegistration('config:environment'),
        STRINGS = _Ember$getOwner$resol.STRINGS;
      return languages.find(function (code) {
        return Object.keys(STRINGS).includes(code);
      });
    },
    updateStrings: function updateStrings() {
      var _Ember$getOwner$resol2 = Ember.getOwner(this).resolveRegistration('config:environment'),
        STRINGS = _Ember$getOwner$resol2.STRINGS;
      Ember.STRINGS = STRINGS[this.getLanguage()] || {};
    }
  });
});
