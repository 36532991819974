define("ln-liga-os/components/scroll-button", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['scroll-button-component'],
    classNameBindings: ['isVisible', 'theme'],
    scrollingElement: null,
    isVisible: false,
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      this.setScrollButton();
      (0, _jquery.default)(this.scrollingElement).on('scroll', function () {
        return _this.setScrollButton();
      });
      (0, _jquery.default)(window).on('orientationchange', function () {
        Ember.run.schedule('afterRender', function () {
          return _this.setScrollButton();
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      var _this2 = this;
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.scrollingElement).off('scroll', function () {
        return _this2.setScrollButton();
      });
      (0, _jquery.default)(window).off('orientationchange', function () {
        Ember.run.schedule('afterRender', function () {
          return _this2.setScrollButton();
        });
      });
    },
    setScrollButton: function setScrollButton() {
      var view = document.querySelector(this.scrollingElement);
      if (!view) {
        return;
      }
      var isButtonVisible = window.innerHeight < view.scrollHeight && view.scrollTop + window.innerHeight < view.scrollHeight;
      this.set('isVisible', isButtonVisible);
    },
    click: function click() {
      (0, _jquery.default)(this.scrollingElement).animate({
        scrollTop: window.innerHeight
      });
    }
  });
});
