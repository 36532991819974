define("ln-liga-os/helpers/sanitize-phone-number", ["exports", "ln-ember-contact-menu/helpers/sanitize-phone-number"], function (_exports, _sanitizePhoneNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _sanitizePhoneNumber.default;
    }
  });
  Object.defineProperty(_exports, "sanitizePhoneNumber", {
    enumerable: true,
    get: function get() {
      return _sanitizePhoneNumber.sanitizePhoneNumber;
    }
  });
});
