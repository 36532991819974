define("ln-liga-os/components/contact-menu", ["exports", "ln-ember-contact-menu/components/contact-menu"], function (_exports, _contactMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _contactMenu.default.extend({
    navigation: Ember.inject.service(),
    state: Ember.inject.service(),
    actions: {
      openProfile: function openProfile() {
        var userId = this.userId;
        var url = this.navigation.removeSlug("/myliga/#/?user=".concat(userId));
        this.navigation.openUrl(url);
        this.state.hideClickCatcher();
      }
    }
  });
});
