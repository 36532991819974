define("ln-liga-os/utils/is-offline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isOffline;
  function isOffline() {
    if (Ember.testing) {
      return true;
    }
    return ['on', '1', 'true', 'yes'].includes(localStorage.getItem('offline'));
  }
});
