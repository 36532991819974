define("ln-liga-os/serializers/vdc/project", ["exports", "ln-ember-data-models/serializers/vdc/project"], function (_exports, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _project.default;
    }
  });
});
