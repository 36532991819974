define("ln-liga-os/models/vdc/fragments/estimated-dimensions", ["exports", "ln-ember-data-models/models/vdc/fragments/estimated-dimensions"], function (_exports, _estimatedDimensions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _estimatedDimensions.default;
    }
  });
});
