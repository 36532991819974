define("ln-liga-os/utils/browser", ["exports", "bowser"], function (_exports, _bowser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatBrowserName = formatBrowserName;
  _exports.formatSupportedBrowsers = formatSupportedBrowsers;
  _exports.isSupportedBrowser = isSupportedBrowser;
  window.global = window; // @see: https://github.com/ef4/ember-auto-import/issues/218#issuecomment-500539712

  function isSupportedBrowser(supportedBrowsers) {
    if (!supportedBrowsers || supportedBrowsers.length === 0) {
      return true;
    }
    return Boolean(Object.keys(supportedBrowsers).find(function (key) {
      var version = supportedBrowsers[key];
      return _bowser.default.name.toLowerCase() === key && _bowser.default.version >= version;
    }));
  }
  function formatBrowserName(key, version) {
    var name = key;
    if (key === 'internet explorer') {
      name += " ".concat(version);
    }
    return name.split(' ').map(function (word) {
      if (word === 'iphone' || word === 'ipad') {
        return word.replace(/^ip/, 'iP');
      }
      return word.replace(/^./, function (c) {
        return c.toUpperCase();
      });
    }).join(' ');
  }
  function formatSupportedBrowsers(supportedBrowsers) {
    return Object.keys(supportedBrowsers).map(function (key) {
      return formatBrowserName(key, supportedBrowsers[key]);
    }).sort(function (a, b) {
      return a.localeCompare(b);
    }).join(', ').replace(/, ([^,]*)$/, ' and $1');
  }
});
