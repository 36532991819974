define("ln-liga-os/helpers/contact-menu/loc", ["exports", "ln-ember-contact-menu/helpers/contact-menu/loc"], function (_exports, _loc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _loc.default;
    }
  });
  Object.defineProperty(_exports, "loc", {
    enumerable: true,
    get: function get() {
      return _loc.loc;
    }
  });
});
