define("ln-liga-os/routes/password-reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    state: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.session.isLoggedIn) {
        return this.router.transitionTo('/!');
      }
    },
    model: function model(_ref) {
      var token = _ref.token;
      return {
        token: token
      };
    },
    activate: function activate() {
      // In some very rare cases appLoaded is set to true when the user lands on the this screen
      // That leads to a missing class name in the application view which messes up the reset pass layout
      this.state.setProperties({
        appLoaded: false,
        appLocation: null
      });
      this.state.resetTitle();
    }
  });
});
