define("ln-liga-os/utils/format-error-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatErrorMessage;
  function formatErrorMessage(error) {
    var model = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var _error$message = error.message,
      errorMessage = _error$message === void 0 ? Ember.String.loc('Unknown error') : _error$message;
    if (model) {
      Ember.get(model, 'errors.content').forEach(function (_ref) {
        var message = _ref.message;
        return errorMessage += "\n".concat(message);
      });
    } else {
      (Ember.get(error, 'details') || []).forEach(function (_ref2) {
        var message = _ref2.message;
        return errorMessage += "\n".concat(message);
      });
    }
    return errorMessage;
  }
});
