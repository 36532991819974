define("ln-liga-os/components/communicator/tab-tasks", ["exports", "ln-liga-os/components/communicator/tab-base", "ln-ember-toolkit/mixins/media-events"], function (_exports, _tabBase, _mediaEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function taskGroupProperty(filter, filterFn) {
    return Ember.computed('filter', 'tasks.[]', function () {
      if (this.tasks && ['all', filter].indexOf(this.filter) !== -1) {
        return this.tasks.filter(filterFn).sortBy('end_date');
      }
      return [];
    });
  }
  var _default = _exports.default = _tabBase.default.extend(_mediaEvents.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    classNames: ['communicator-tab-tasks-component'],
    classNameBindings: ['hasAnyOpenPopup'],
    tasks: null,
    isLoading: true,
    hasAnyOpenPopup: false,
    filter: 'all',
    urgentTasks: taskGroupProperty('urgent', function (task) {
      return task.get('isCritical');
    }),
    todayTasks: taskGroupProperty('today', function (task) {
      return task.get('toDoToday');
    }),
    comingTasks: taskGroupProperty('coming', function (task) {
      return task.get('daysLeft') > 0;
    }),
    hasNoEntries: Ember.computed('urgentTasks.[]', 'todayTasks.[]', 'comingTasks.[]', function () {
      return this.get('urgentTasks.length') + this.get('todayTasks.length') + this.get('comingTasks.length') === 0;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.loadData();
    },
    actions: {
      infoDidChange: function infoDidChange(open) {
        this.set('hasAnyOpenPopup', open);
      }
    },
    loadData: function loadData() {
      var _this = this;
      var userId = this.session.userId;
      if (userId) {
        this.set('isLoading', true);
        this.store.query('vdc/task', {
          status: 'Open',
          user: userId,
          embed: ['author']
        }).then(function (tasks) {
          if (_this.isDestroyed) {
            return;
          }
          _this.set('tasks', tasks.filter(function (task) {
            return task.get('isOpen');
          }));
          _this.set('isLoading', false);
        }).catch(function () {
          if (_this.isDestroyed) {
            return;
          }
          _this.set('isLoading', false);
        });
      }
    }
  });
});
