define("ln-liga-os/components/check-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['check-icon-component'],
    classNameBindings: ['isCorrect'],
    isCorrect: null
  });
});
