define("ln-liga-os/utils/set-if-changed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setIfChanged;
  _exports.propertyHasChanged = propertyHasChanged;
  function propertyHasChanged(obj, key, value) {
    return JSON.stringify(Ember.get(obj, key)) !== JSON.stringify(value);
  }
  function setIfChanged(obj, key, value) {
    if (propertyHasChanged(obj, key, value) && !obj.isDestroyed) {
      Ember.set(obj, key, value);
      return true;
    }
    return false;
  }
});
