define("ln-liga-os/components/micro-menu", ["exports", "ln-ember-contact-menu/components/micro-menu"], function (_exports, _microMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _microMenu.default;
    }
  });
});
