define("ln-liga-os/transforms/nullable-string", ["exports", "ln-ember-data-models/transforms/nullable-string"], function (_exports, _nullableString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _nullableString.default;
    }
  });
});
