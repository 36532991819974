define("ln-liga-os/models/vdc/fragments/text-module-group-comment-link", ["exports", "ln-ember-data-models/models/vdc/fragments/text-module-group-comment-link"], function (_exports, _textModuleGroupCommentLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _textModuleGroupCommentLink.default;
    }
  });
});
