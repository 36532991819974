define("ln-liga-os/components/communicator/filter-birthdays", ["exports", "ln-liga-os/components/communicator/filter-base"], function (_exports, _filterBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _filterBase.default.extend({
    classNames: ['communicator-filter-birthdays-component'],
    defaultFilter: 'all',
    filters: null,
    init: function init() {
      this.set('filters', [{
        key: 'all',
        label: 'All'.loc()
      }, {
        key: 'today',
        label: 'Today'.loc()
      }, {
        key: 'coming',
        label: 'Upcoming'.loc()
      }]);
      this._super.apply(this, arguments);
    }
  });
});
