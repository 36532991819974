define("ln-liga-os/initializers/google-analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function safeLoadScript(src) {
    return new Promise(function (resolve) {
      var script = document.querySelector("script[src=\"".concat(src, "\"]"));
      if (script) {
        resolve(undefined);
      } else {
        script = document.createElement('script');
        script.src = src;
        script.onload = function () {
          return resolve(undefined);
        };
        document.head.appendChild(script);
      }
    });
  }
  function initialize(application) {
    var isEnabled = application.get('config.disable.googleAnalytics') !== true;
    var tagManagerId = application.get('config.googleTagManagerId');
    if (isEnabled && tagManagerId) {
      var src = "https://www.googletagmanager.com/gtag/js?id=".concat(tagManagerId);
      safeLoadScript(src).then(function () {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }
          window.dataLayer.push(args);
        }
        gtag('js', new Date());
        gtag('config', tagManagerId);
      });
    }
  }
  var _default = _exports.default = {
    name: 'google-analytics',
    initialize: initialize
  };
});
