define("ln-liga-os/utils/create-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createObject;
  function createObject(container, name) {
    var properties = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var owner = Ember.getOwner(container);
    var Object = owner.resolveRegistration("object:".concat(name));
    return Object.create(owner.ownerInjection(), properties);
  }
});
