define("ln-liga-os/components/communicator/filter-contacts", ["exports", "ln-liga-os/components/communicator/filter-base"], function (_exports, _filterBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _filterBase.default.extend({
    rights: Ember.inject.service(),
    classNames: ['communicator-filter-contacts-component'],
    defaultFilter: 'all',
    filters: null,
    init: function init() {
      var _this = this;
      this.set('filters', [{
        key: 'all',
        label: Ember.String.loc('All')
      }, {
        key: 'projects',
        label: Ember.String.loc('Projects')
      }]);
      this.rights.can('READ', 'EmailDistributor').then(function (canRead) {
        if (canRead) {
          _this.filters.addObject({
            key: 'lists',
            label: Ember.String.loc('Lists')
          });
        }
      });
      this._super.apply(this, arguments);
    }
  });
});
