define("ln-liga-os/models/vdc/annotation", ["exports", "ln-ember-data-models/models/vdc/annotation"], function (_exports, _annotation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _annotation.default;
    }
  });
});
