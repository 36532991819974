define("ln-liga-os/models/vdc/fragments/attribute-value", ["exports", "ln-ember-data-models/models/vdc/fragments/attribute-value"], function (_exports, _attributeValue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _attributeValue.default;
    }
  });
});
