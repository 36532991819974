define("ln-liga-os/components/contact-list/item-contact", ["exports", "ln-liga-os/components/contact-list/item-base", "ln-ember-toolkit/mixins/media-events"], function (_exports, _itemBase, _mediaEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _itemBase.default.extend(_mediaEvents.default, {
    classNames: ['contact-list-item-contact-component', 'mediaMatcher.isMobile'],
    classNameBindings: ['hasOpenPopup', 'isScrolling'],
    hasOpenPopup: false,
    onPopupChange: function onPopupChange() {},
    contact: Ember.computed.alias('item.contact'),
    hasPhoneInformation: Ember.computed.or('contact.phone', 'contact.fax', 'contact.mobile_phone'),
    nameInitials: Ember.computed('contact', function () {
      var user = this.contact;
      var initials = user.first_name.charAt(0).concat(user.name.charAt(0));
      return initials;
    }),
    sendPopupChange: Ember.observer('hasOpenPopup', function () {
      this.onPopupChange(this.hasOpenPopup);
    }),
    actions: {
      toggleDetails: function toggleDetails() {
        this.toggleProperty('hasOpenPopup');
      },
      closePopup: function closePopup() {
        this.set('hasOpenPopup', false);
      }
    },
    click: function click() {
      if (this.isMobile) {
        this.set('hasOpenPopup', true);
      }
    },
    mouseLeave: function mouseLeave() {
      this.set('hasOpenPopup', false);
    }
  });
});
