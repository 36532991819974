define("ln-liga-os/components/communicator/tab-settings", ["exports", "ln-liga-os/components/communicator/tab-base", "ln-liga-os/utils/normalize-error-response", "ln-ember-config-utils/utils/inject-theme"], function (_exports, _tabBase, _normalizeErrorResponse, _injectTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _tabBase.default.extend({
    state: Ember.inject.service(),
    api: Ember.inject.service(),
    settings: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: ['communicator-tab-settings-component'],
    classNameBindings: ['hasPasswordValues', 'hasPasswordFocus'],
    settingValues: null,
    password: null,
    errorMessage: null,
    errorDetails: null,
    alwaysShowCommunicatorOptions: null,
    notificationSoundOptions: null,
    hasPasswordFocus: false,
    theme: (0, _injectTheme.default)(),
    currentUser: Ember.computed.alias('state.user'),
    languageOptions: Ember.computed('state.languages.[]', function () {
      return (this.get('state.languages') || []).map(function (language) {
        return {
          label: Ember.get(language, 'name'),
          value: Number(Ember.get(language, 'id'))
        };
      });
    }),
    showErrorMessage: Ember.computed('errorMessage', 'errorDetails', function () {
      var details = this.errorDetails || {};
      var hasDetails = Object.keys(details).find(function (key) {
        return details[key];
      });
      return Boolean(this.errorMessage || hasDetails);
    }),
    hasPasswordValues: Ember.computed('password.{old,new,new_rep}', function () {
      return Boolean(this.get('password.old') || this.get('password.new') || this.get('password.new_rep'));
    }),
    resetPasswordError: Ember.observer('password.old', 'password.new', 'password.new_rep', function (_, key) {
      key = key.replace(/\./g, '_');
      if (this.get("errorDetails.".concat(key))) {
        this.set("errorDetails.".concat(key), null);
        this.notifyPropertyChange('errorDetails');
      }
    }),
    updateNotificationSound: Ember.observer('settingValues.NotificationSound', function () {
      var value = this.get('settingValues.NotificationSound');
      this.settings.setValue('NotificationSound', value);
    }),
    updateAlwaysShowCommunicator: Ember.observer('settingValues.AlwaysShowCommunicator', function () {
      var value = this.get('settingValues.AlwaysShowCommunicator');
      this.settings.setValue('AlwaysShowCommunicator', value);
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('password', {
        old: null,
        new: null,
        new_rep: null
      });
      this.set('alwaysShowCommunicatorOptions', [{
        label: 'On'.loc(),
        value: true
      }, {
        label: 'Off'.loc(),
        value: false
      }]);
      this.set('notificationSoundOptions', [{
        label: 'On'.loc(),
        value: true
      }, {
        label: 'Off'.loc(),
        value: false
      }]);
      this.updateSettingsValues();
    },
    actions: {
      savePassword: function savePassword() {
        this.savePassword();
      },
      hideErrorMessage: function hideErrorMessage() {
        this.set('errorMessage', null);
      },
      setLanguage: function setLanguage(languageId) {
        this.set('currentUser.language_id', languageId);
        this.saveUser();
      },
      setAlwaysShowCommunicator: function setAlwaysShowCommunicator(value) {
        this.set('settingValues.AlwaysShowCommunicator', value);
        this.saveSettings();
      },
      setNotificationSound: function setNotificationSound(value) {
        this.set('settingValues.NotificationSound', value);
        this.saveSettings();
      },
      setPasswordFocus: function setPasswordFocus(focus) {
        var _this = this;
        Ember.run.next(function () {
          return _this.set('hasPasswordFocus', focus);
        });
      }
    },
    resetAllPasswordError: function resetAllPasswordError() {
      this.set('errorDetails', {});
      this.set('errorMessage', null);
    },
    updateSettingsValues: function updateSettingsValues() {
      var _this2 = this;
      this.settings.getValues(['NotificationSound', 'AlwaysShowCommunicator']).then(function (values) {
        return _this2.set('settingValues', values);
      });
    },
    showError: function showError(error) {
      if (typeof error === 'string') {
        this.set('errorMessage', error);
      } else {
        var _normalizeErrorRespon = (0, _normalizeErrorResponse.default)(error),
          message = _normalizeErrorRespon.message,
          details = _normalizeErrorRespon.details;
        this.set('errorMessage', message);
        this.set('errorDetails', details);
      }
    },
    saveUser: function saveUser() {
      var _this3 = this;
      var user = this.currentUser;
      if (!user) {
        return;
      }
      this.set('isSaving', true);
      return user.save().then(function () {
        if (_this3.isDestroyed) {
          return;
        }
        var lang = _this3.get('state.languages').findBy('id', String(user.get('language_id')));
        if (lang) {
          _this3.state.setLanguage(lang.get('code'));
        }
      }).catch(function (error) {
        if (_this3.isDestroyed) {
          return;
        }
        _this3.showError(error);
      }).finally(function () {
        if (_this3.isDestroyed) {
          return;
        }
        _this3.set('isSaving', false);
      });
    },
    saveSettings: function saveSettings() {
      var _this4 = this;
      return this.settings.saveAllSettings().catch(function (error) {
        if (_this4.isDestroyed) {
          return;
        }
        _this4.showError(error);
        _this4.settings.reloadAllSettings().then(function () {
          return _this4.updateSettingsValues();
        });
      });
    },
    savePassword: function savePassword() {
      var _this5 = this;
      this.resetAllPasswordError();
      if (this.get('password.new') !== this.get('password.new_rep')) {
        return this.set('errorDetails', {
          password_new_rep: 'The new password and the password repeat must match'
        });
      }
      var userId = this.get('currentUser.id');
      if (!userId) {
        return;
      }
      this.set('isSaving', true);
      return this.api.update('myliga', ['users', userId, 'password'], {
        password_new: this.get('password.new'),
        password_old: this.get('password.old')
      }).then(function () {
        if (_this5.isDestroyed) {
          return;
        }
        _this5.set('passwordSaved', true);
      }).catch(function (error) {
        if (_this5.isDestroyed) {
          return;
        }
        _this5.showError(error);
      }).finally(function () {
        if (_this5.isDestroyed) {
          return;
        }
        _this5.set('isSaving', false);
      });
    }
  });
});
