define("ln-liga-os/app", ["exports", "ln-liga-os/pollyfills/string-repeat", "ln-liga-os/pollyfills/object-assign", "ln-liga-os/pollyfills/url", "@sentry/ember", "ember-load-initializers", "ln-liga-os/resolver", "ln-liga-os/config/environment"], function (_exports, _stringRepeat, _objectAssign, _url, _ember, _emberLoadInitializers, _resolver, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  if (!['development', 'test'].includes(_environment.default.environment)) {
    (0, _ember.InitSentryForEmber)({
      release: "".concat(_environment.default.APP.name, "@").concat(_environment.default.APP.version)
    });
  }
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = _exports.default = App;
});
