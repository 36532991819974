define("ln-liga-os/services/micro-menu", ["exports", "ln-ember-contact-menu/services/micro-menu"], function (_exports, _microMenu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _microMenu.default.extend({
    state: Ember.inject.service(),
    session: Ember.computed.alias('state.session'),
    horizontalAttachement: 'left'
  });
});
