define("ln-liga-os/components/contact-list/item-project", ["exports", "ln-liga-os/components/contact-list/item-base"], function (_exports, _itemBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _itemBase.default.extend({
    api: Ember.inject.service(),
    classNames: ['contact-list-item-project-component'],
    project: Ember.computed.alias('item.project'),
    actions: {
      openProjectMail: function openProjectMail(event) {
        event.stopPropagation();
        var project = this.project;
        var query = {
          project: project.id,
          show_in_communicator_contact_list: true
        };
        this.api.read('myliga', ['liga3000_contacts', query]).then(function (response) {
          var addresses = (response.result || []).map(function (contact) {
            return contact.email;
          });
          window.open("mailto:".concat(addresses.join(',')));
        });
      }
    },
    click: function click() {
      this.onProjectClick(this.item);
    }
  });
});
