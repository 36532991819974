define("ln-liga-os/components/audio-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    state: Ember.inject.service(),
    tagName: 'audio',
    attributeBindings: ['src'],
    src: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.state.on('notification-sound', this, this.play);
    },
    willRemoveElemnt: function willRemoveElemnt() {
      this._super.apply(this, arguments);
      this.state.off('notification-sound', this, this.play);
    },
    // TODO: Needs to be triggered somehow when the PubSub Service is done
    play: function play() {
      if (this.isDestroyed) {
        return;
      }
      var player = this.element;
      player.currentTime = 0;
      player.play();
    }
  });
});
