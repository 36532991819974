define("ln-liga-os/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    router: Ember.inject.service(),
    state: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;
      return this.session.checkAuth().then(function () {
        if (_this.session.isLoggedIn) {
          return _this.router.transitionTo('/!');
        }
      }).catch(function () {
        if (_this.session.isLoggedIn) {
          return _this.router.transitionTo('/!');
        }

        // Otherwise, swallow the error.
        // If the user is not logged in, they should be able to see the login screen.
      });
    },
    activate: function activate() {
      // In some very rare cases appLoaded is set to true when the user lands on the login screen
      // That leads to a missing class name in the application view which messes up the login layout
      // comes from login route, initially commited by JG
      this.state.setProperties({
        appLoaded: false,
        appLocation: null
      });
      this.state.resetTitle();
    },
    setupController: function setupController(controller) {
      controller.setProperties({
        username: this.get('config.login.username'),
        password: this.get('config.login.password'),
        showPasswordReset: false,
        showPasswordResetRequested: false
      });
    }
  });
});
