define("ln-liga-os/components/resized-image", ["exports", "ln-ember-toolkit/components/resized-image"], function (_exports, _resizedImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _resizedImage.default;
    }
  });
});
