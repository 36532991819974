define("ln-liga-os/initializers/register-objects", ["exports", "ln-liga-os/objects/communicator-tab-item"], function (_exports, _communicatorTabItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.register('object:communicator-tab-item', _communicatorTabItem.default);
  }
  var _default = _exports.default = {
    name: 'register-objects',
    initialize: initialize
  };
});
