define("ln-liga-os/components/form-elements/date-range-input", ["exports", "ln-ember-form-elements/components/form-elements/date-range-input"], function (_exports, _dateRangeInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dateRangeInput.default;
    }
  });
});
