define("ln-liga-os/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.transition(this.hasClass('click-catcher-animation'), this.use('fade', {
      duration: 3000
    }));
  }
});
