define("ln-liga-os/components/communicator/notification-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    microMenu: Ember.inject.service(),
    classNames: ['communicator-notification-item-component', 'list-item'],
    classNameBindings: ['notification.context_type:has-action', 'notification.read_state:is-read', 'notification.flagged:is-flagged', 'notification.archived:is-archived', 'notification.sender_id:is-user'],
    notification: null,
    option: function option() {},
    filterIsArchive: Ember.computed.equal('filter', 'archive'),
    actions: {
      option: function option() {
        this.option.apply(this, arguments);
      },
      openMenu: function openMenu(component, e) {
        this.microMenu.open(e.target.id, component);
        return false;
      }
    },
    click: function click() {
      this.triggerAction({
        action: 'openNotification',
        actionContext: this.notification
      });
    }
  });
});
