define("ln-liga-os/components/form-elements/calendar/month-list", ["exports", "ln-ember-form-elements/components/form-elements/calendar/month-list"], function (_exports, _monthList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _monthList.default;
    }
  });
});
