define("ln-liga-os/utils/url", ["exports", "ln-ember-toolkit/utils/regexp", "ln-ember-toolkit/utils/liga-os"], function (_exports, _regexp, _ligaOs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cleanAppUrl = cleanAppUrl;
  _exports.cleanUrl = cleanUrl;
  _exports.decodeHashPart = decodeHashPart;
  _exports.encodeHashPart = encodeHashPart;
  _exports.replaceLocationHash = replaceLocationHash;
  function replaceLocationHash(search, replace) {
    var urlObj = new URL(location.href);
    urlObj.hash = location.hash.replace(search, replace);
    history.replaceState(undefined, undefined, urlObj.toString());
  }
  function cleanAppUrl(url) {
    // Escape special characters with slash "foo[bar]-{baz}" -> "foo\[bar\]\-\{baz\}"
    var host = (0, _regexp.escapeExpStr)(location.host);
    var _location = location,
      port = _location.port;
    var hostExp = port ? "^https?://".concat(host, "(:").concat(port, ")?([^:]|$)") : "^https?://".concat(host, "([^:]|$)");
    var replace = port ? '$2' : '$1';
    var urlParsed = new URL(url, location.origin);
    urlParsed.searchParams.delete(_ligaOs.PREFIX_QUERY_PARAM_NAME);
    url = urlParsed.toString();
    return url
    // Replace with relative url if origin matches.
    // "https://example.com/foo?bar=baz" -> "/foo?bar=baz" if location.origin is "https://example.com"
    .replace(new RegExp(hostExp), replace)
    // Remove api-session-token query param.
    .replace(/api-session-token=[a-z0-9-]+/, '')
    // Remove question-mark if it’s not followed by a query param (if api-session-token from above was the only query param).
    // "http://example.com/foo?" -> "http://example.com/foo" and "http://example.com/foo?#bar" -> "http://example.com/foo#bar"
    .replace(/\?(#|$)/, '$1');
  }
  function cleanUrl(url) {
    return (url || ''
    // Replace multiple slashes with one except the case when it’s after semicolon.
    // "http://example.com/////foo////bar///" -> "http://example.com/foo.bar/"
    ).replace(/(^|[^:])\/\/+/g, '$1/')
    // Replace urlencoded pipes with decoded pipes.
    .replace(/%7C/g, '|');
  }
  function decodeHashPart(part) {
    var pathRegExp = /^!\/([a-z0-9-]+)([/#?])(.*)/i;
    var hostRegExp = /^!(\/\/.+)$/;
    if (part && part !== '!') {
      part = part.replace(/(^\s*|\s*)$/g, '').replace(/^#/, '');
      if (part.match(hostRegExp)) {
        part = part.replace(hostRegExp, "".concat(location.protocol, "$1"));
      } else if (part.match(pathRegExp)) {
        part = part.replace(pathRegExp, '/$1$2$3');
      } else if (part !== '!') {
        console.error("invalid url part \"".concat(part, "\""));
        part = '!';
      }
      part = cleanUrl(part);
      part = part.replace(/\|\|/g, '#');
      part = part.replace(/\|/g, '?');
      return part;
    }
    return '';
  }
  function encodeHashPart(url) {
    url = cleanUrl(url);
    var appRegExp = /^(\/[a-z0-9-]+\/?)(#\/)?(.*)/i;
    var hostRegExp = /^https?:(\/\/.+)$/;
    if (url) {
      if (url.match(hostRegExp)) {
        url = url.replace(hostRegExp, '!$1');
      } else {
        var matches = url.match(appRegExp);
        if (matches) {
          url = "!".concat(matches[1]).concat(matches[3] || '');
        } else {
          url = '';
        }
      }
      url = url.replace(/#/g, '||');
      url = url.replace(/\?/g, '|');
      return url;
    }
    return '';
  }
});
