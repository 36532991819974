define("ln-liga-os/initializers/model", ["exports", "ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    /**
      @class Model
      @namespace DS
    */
    _model.default.reopen({
      /**
       * Set a given attribute to its `defaultValue`.
       * If no `defaultValue` was specified in the attribute, it will default to `null`.
       * If the given attribute can't be found on the model, it will return undefined.
       *
       * @param {string} attributeName
       * @returns {*} the value that was set or undefined if the attribute could not be reset
       */
      resetToDefault: function resetToDefault(attributeName) {
        var attributes = Ember.get(this.constructor, 'attributes');
        var attribute = attributes.get(attributeName);
        if (!attribute) {
          return;
        }
        var defaultValue = attribute.options.defaultValue;
        return this.set(attributeName, typeof defaultValue === 'function' ? defaultValue() : defaultValue);
      }
    });
  }
  var _default = _exports.default = {
    name: 'ln-ember-data-models',
    after: 'ember-data',
    initialize: initialize
  };
});
