define("ln-liga-os/adapters/vdc/contract", ["exports", "ln-ember-data-models/adapters/vdc/contract"], function (_exports, _contract) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _contract.default;
    }
  });
});
