define("ln-liga-os/locations/custom-hash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HashLocation.extend({
    implementation: 'custom-hash',
    replaceURL: function replaceURL(path) {
      var url = new URL(location.href);
      url.hash = "#".concat(path);
      this.location.replace(url);
      this.set('lastSetURL', path);
    }
  });
});
