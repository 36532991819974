define("ln-liga-os/utils/regexp", ["exports", "ln-ember-toolkit/utils/regexp"], function (_exports, _regexp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "escapeExpStr", {
    enumerable: true,
    get: function get() {
      return _regexp.escapeExpStr;
    }
  });
  Object.defineProperty(_exports, "replaceAll", {
    enumerable: true,
    get: function get() {
      return _regexp.replaceAll;
    }
  });
});
