define("ln-liga-os/services/storage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function useCookies() {
    if ((typeof localStorage === "undefined" ? "undefined" : _typeof(localStorage)) === 'object') {
      if (localStorage.getItem('storage-supported-test:', true)) {
        return false;
      }
      try {
        localStorage.setItem('storage-supported-test', true);
        return false;
      } catch (e) {
        // Mute error
      }
    }
    return true;
  }
  function setCookie(key, value) {
    var expires = new Date();
    expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie = ["".concat(key, "=").concat(value), "expires=".concat(expires.toGMTString()), 'path=/'].join('; ');
  }
  function getCookie(key) {
    var pre = "".concat(key, "=");
    var value = document.cookie.split(';').map(function (c) {
      return c.replace(/(^\s+|\s+$)/g, '');
    }).find(function (c) {
      return c.indexOf(pre) === 0;
    });
    if (typeof value === 'string') {
      return value.substring(pre.length, value.length);
    }
    return null;
  }
  var _default = _exports.default = Ember.Service.extend({
    get: function get(key, defaultValue) {
      key = "storage:".concat(key);
      var value = null;
      try {
        value = JSON.parse(useCookies() ? getCookie(key) : localStorage.getItem(key));
      } catch (ex) {/* console.error(ex) */}
      return Ember.isNone(value) ? defaultValue : value;
    },
    set: function set(key, value) {
      key = "storage:".concat(key);
      try {
        value = JSON.stringify(value);
      } catch (ex) {
        value = null;
      }
      if (useCookies()) {
        return setCookie(key, value);
      }
      localStorage.setItem(key, value);
    }
  });
});
