define("ln-liga-os/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['loading-spinner-component'],
    classNameBindings: ['color'],
    color: null
  });
});
