define("ln-liga-os/components/contact-list/collection", ["exports", "ln-ember-toolkit/mixins/media-events", "jquery"], function (_exports, _mediaEvents, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_mediaEvents.default, {
    classNames: ['contact-list-collection-component', 'fade-in-content'],
    classNameBindings: ['isScrolling', 'hasAnyOpenPopup'],
    columnWidth: 300,
    rowHeight: 60,
    scrollLeft: 0,
    scrollTop: 0,
    hasAnyOpenPopup: false,
    // rowHeight for the desktop version
    desktopRowHeight: 60,
    // rowHeight for the mobile version
    mobileRowHeight: 80,
    // Number of contacts left on the bottom before loading more data
    loadMoreThreshold: 50,
    isScrolling: false,
    loadNextPage: function loadNextPage() {},
    actions: {
      scrollChange: function scrollChange(scrollLeft, scrollTop) {
        this.set('isScrolling', true);
        Ember.run.debounce(this, this.set, 'isScrolling', false, 150);
        if (this.scrollTop < scrollTop) {
          var collection = (0, _jquery.default)('.contacts-collection > div', this.element);
          if (scrollTop > collection.get(0).scrollHeight - 2500) {
            Ember.run.throttle(this, this.loadNextPage, 200);
          }
        }
        this.set('scrollLeft', scrollLeft);
        this.set('scrollTop', scrollTop);
      },
      onPopupChange: function onPopupChange(open) {
        this.set('hasAnyOpenPopup', open);
      }
    }
  });
});
