define("ln-liga-os/utils/inject-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = injectConfig;
  function injectConfig() {
    return Ember.computed(function () {
      var _Ember$getOwner$resol = Ember.getOwner(this).resolveRegistration('config:environment'),
        config = _Ember$getOwner$resol.APP.config;
      return Ember.Object.create(config);
    });
  }
});
