define("ln-liga-os/components/form-condition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['form-condition-component'],
    classNameBindings: ['isMet'],
    label: null,
    isMet: false
  });
});
