define("ln-liga-os/utils/string-utils", ["exports", "ln-ember-toolkit/utils/string-utils"], function (_exports, _stringUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "toLabel", {
    enumerable: true,
    get: function get() {
      return _stringUtils.toLabel;
    }
  });
});
