define("ln-liga-os/components/contact-list/item-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    microMenu: Ember.inject.service(),
    classNames: ['contact-list-item-base-component'],
    actions: {
      openMenu: function openMenu(component, e) {
        this.microMenu.open(e.target.id, component);
        return false;
      }
    }
  });
});
