define("ln-liga-os/components/click-catcher", ["exports", "ln-ember-toolkit/mixins/media-events", "jquery"], function (_exports, _mediaEvents, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_mediaEvents.default, {
    state: Ember.inject.service(),
    classNames: ['click-catcher-component'],
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.isMobile) {
        var clone = (0, _jquery.default)(this.element).clone().attr('id', "".concat((0, _jquery.default)(this.element).attr('id'), "-clone")).addClass('destroying');
        clone.get(0).addEventListener('animationend', function () {
          return clone.remove();
        }, false);
        (0, _jquery.default)(this.element).parent().append(clone);
      }
    },
    click: function click() {
      this.state.hideClickCatcher();
    }
  });
});
