define("ln-liga-os/initializers/custom-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'custom-events',
    initialize: function initialize(application) {
      application.customEvents = application.customEvents || {};
      application.customEvents.wheel = 'wheel';
    }
  };
});
