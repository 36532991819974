define("ln-liga-os/components/communicator/tab-applications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    navigation: Ember.inject.service(),
    state: Ember.inject.service(),
    hoveredItem: null,
    classNames: ['communicator-tab-applications-component'],
    close: function close() {},
    itemHiglight: Ember._action(function (item, higlighted) {
      this.set('hoveredItem', higlighted ? item : null);
    }),
    removeErrorMessage: Ember._action(function () {
      this.set('state.applicationsError', null);
    }),
    itemClick: Ember._action(function (_ref) {
      var url = _ref.url;
      var navigation = this.navigation;
      if (navigation.belongsToExternalApp(url)) {
        // Keep Communicator open when eternal Apps are opened
        navigation.openUrl(url);
      } else {
        this.close();
        Ember.run.later(function () {
          return navigation.openUrl(url);
        }, 500);
      }
    })
  });
});
