define("ln-liga-os/router", ["exports", "ln-liga-os/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('password-reset', {
      path: '/password-reset/:token'
    });
    this.route('main', {
      path: '/*url'
    });
  });
  var _default = _exports.default = Router;
});
