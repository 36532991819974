define("ln-liga-os/components/communicator/tab-notifications", ["exports", "jquery", "ln-liga-os/components/communicator/tab-base", "ln-ember-toolkit/mixins/media-events"], function (_exports, _jquery, _tabBase, _mediaEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var assign = Object.assign;
  var _default = _exports.default = _tabBase.default.extend(_mediaEvents.default, Ember.Evented, {
    communicator: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    navigation: Ember.inject.service(),
    classNames: ['communicator-tab-notifications-component', 'list', 'notifications-list'],
    classNameBindings: ['filterClass', 'isLoading'],
    lastRequest: null,
    hasMore: true,
    notifications: null,
    filter: 'current',
    pagingLimit: 20,
    searchTerm: '',
    isLoading: true,
    close: function close() {},
    filterClass: Ember.computed('filter', function () {
      return "filter-".concat(this.filter);
    }),
    filterObserver: Ember.observer('filter', function () {
      Ember.run.throttle(this, this.loadData, true, 100);
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.element).on('scroll', function (event) {
        return _this.onScroll(event);
      });
      Ember.run.scheduleOnce('afterRender', function () {
        return _this.loadData(true);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      (0, _jquery.default)(this.element).off('scroll');
    },
    actions: {
      openNotification: function openNotification(notification) {
        if (notification.get('link')) {
          this.navigation.openUrl(notification.get('link'));
          this.close();
          return;
        }
        var resource = notification.get('context_type');
        var resourceId = notification.get('context_id');
        if (this.navigation.canOpenResource(resource, resourceId)) {
          this.navigation.openResource(resource, resourceId);
          this.close();
        }
      },
      searchValueDidChange: function searchValueDidChange(value) {
        this.set('searchTerm', value);
        this.loadData(true);
      },
      filter: function filter(filterKey) {
        this.set('filter', filterKey);
      },
      option: function option(optionKey, notification) {
        var prop;
        switch (optionKey) {
          case 'toggleRead':
            prop = 'read_state';
            break;
          case 'toggleFlag':
            prop = 'flagged';
            break;
          case 'toggleArchive':
            prop = 'archived';
            break;
          default:
            return;
        }
        notification.toggleProperty(prop);
        notification.save();
      }
    },
    loadData: function loadData() {
      var _this2 = this;
      var reload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      if (!this.session.isLoggedIn) {
        return;
      }
      if (reload) {
        this.set('notifications', []);
        this.set('hasMore', true);
      }
      if (!this.hasMore) {
        return;
      }
      var query = this.createQuery({
        paging_offset: this.get('notifications.length') || 0,
        paging_limit: this.pagingLimit
      });
      var lastRequestHash = JSON.stringify(query);
      if (this.isLoading && this.lastRequestHash === lastRequestHash) {
        return;
      }
      this.set('lastRequestHash', lastRequestHash);
      this.set('isLoading', true);
      var request = this.store.query('vdc/notification', query).then(function (notifications) {
        if (_this2.isDestroyed || request !== _this2.lastRequest) {
          return;
        }
        _this2.notifications.pushObjects(notifications.toArray().sort(function (a, b) {
          return Ember.compare(b.get('datetime'), a.get('datetime'));
        }));
        _this2.set('hasMore', _this2.pagingLimit === notifications.get('meta.result_count'));
      }).catch(function (error) {
        return console.error(error);
      }).finally(function () {
        if (_this2.isDestroyed || request !== _this2.lastRequest) {
          return;
        }
        _this2.set('isLoading', false);
      });
      this.set('lastRequest', request);
    },
    createQuery: function createQuery() {
      var add = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var filter = this.filter;
      var query = {};
      switch (filter) {
        case 'current':
          assign(query, {
            archived: false,
            current: true
          });
          break;
        case 'flagged':
          assign(query, {
            archived: false,
            flagged: true
          });
          break;
        case 'unread':
          assign(query, {
            archived: false,
            read_state: false
          });
          break;
        case 'archive':
          assign(query, {
            archived: true,
            current: false
          });
          break;
      }
      var term = this.getProperSearchTerm();
      if (term !== '') {
        assign(query, {
          search_text: term
        });
      }
      return assign(query, add);
    },
    getProperSearchTerm: function getProperSearchTerm() {
      var term = this.searchTerm || '';
      var numChars = term.replace(/\s+/g, '').length; // without whitespace
      if (numChars <= 2) {
        term = '';
      }
      return term;
    },
    updateUnreadCountBadge: function updateUnreadCountBadge() {
      this.communicator.updateBadges(false);
    },
    onScroll: function onScroll(event) {
      var target = (0, _jquery.default)(event.target);
      var scrollBottom = target.scrollTop() + target.height();
      var scrollHeight = target.prop('scrollHeight');
      if (scrollBottom + scrollHeight * 0.25 >= scrollHeight) {
        // reached end of list
        this.loadData();
      }
    }
  });
});
