define("ln-liga-os/components/contact-list/item-client", ["exports", "ln-liga-os/components/contact-list/item-base"], function (_exports, _itemBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _itemBase.default.extend({
    classNames: ['contact-list-item-client-component'],
    client: Ember.computed.alias('item.client'),
    click: function click() {
      this.onClientClick(this.item);
    }
  });
});
